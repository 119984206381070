import {
  Box,
  Grid,
  MenuItem,
  Pagination,
  SelectChangeEvent,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import { ToastContainer, toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import BannerSection from "../../features/bannerSection/bannerSection";
import MainHeader from "../../features/header/mainHeader/mainHeader";
import MobileHeader from "../../features/header/mobileHeader/mobileHeader";
import SubHeader from "../../features/header/subHeader/subHeader";
import FullDetailsModal from "../../features/modals/fullDetailsModal/fullDetailsModal";
import SearchResultLayout from "../../features/searchResultLayout/searchResultLayout";
import SearchResultLayoutMobile from "../../features/searchResultLayout/searchResultLayoutMobile";
import TextualAdvertisingSection from "../../features/textualAdvertisingSection/textualAdvertisingSection";
import { AdvanceSearchFilterModel } from "../../models/advanceSearchFilterModel";
import {
  INIT_ADVANCE_SEARCH,
  INIT_PAGED_RESULT_COMPANY,
  INIT_PAGE_SIZE,
} from "../../models/init_data/initData";
import {
  getAllBanners,
  getCompaniesAuthorized,
  getCompaniesUnauthorized,
  resetCompanyById,
  resetSearch,
  selectAllBanners,
  selectCompanySearchResponse,
  selectLoadingFullDetailsStatus,
  selectSearchStatus,
} from "../../slices/companySlice";
import { useHomePageStyles } from "./homePageStyles";
import { CompanyModel } from "../../models/company/companyModel";
import { CompanySearchResponse } from "../../models/company/companySearchResponse";
import { TextualAdvertisementModel } from "../../models/company/companyTextualAdvertisementModel";
import FullDetailsModalTextualAds from "../../features/modals/fullDetailsModalTextualAds/fullDetailsModalTextualAds";
import { PagedResult } from "../../models/app/pagedResult";
import PromoteBusinessSection from "../../features/promoteBusinessSection/promoteBusinessSection";
import {
  logout,
  resetResetPassword,
  resetResetPasswordStatus,
  selectCurrentAccount,
  selectLoginResponse,
  selectLoginStatus,
  selectResetPasswordResponse,
} from "../../slices/authSlice";
import Sidebar from "../../features/sidebar/sidebar";
import ActivitySelectionModal from "../../features/modals/activitySelectionModal/activitySelectionModal";
import { updateAfterSelectingCategories } from "../../slices/onboardingSlice";
import {
  resetOverallRatingStatus,
  selectOverallRatingStatus,
} from "../../slices/overallRatingSlice";
import { ApiStatus } from "../../models/app/apiStatus";

export default function HomePage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { classes } = useHomePageStyles();
  const theme = useTheme();

  const sliderRef = useRef<HTMLDivElement | null>(null);
  const divRef = useRef<HTMLDivElement | null>(null);
  const overallRatingStatus = useAppSelector(selectOverallRatingStatus);
  const fullDetailsStatus = useAppSelector(selectLoadingFullDetailsStatus);
  const companySearchResponse: CompanySearchResponse | null = useAppSelector(
    selectCompanySearchResponse
  );
  const currentAccount = useAppSelector(selectCurrentAccount);
  const loginResponse = useAppSelector(selectLoginResponse);
  const loginStatus = useAppSelector(selectLoginStatus);

  const banners = useSelector(selectAllBanners);
  const searchStatus = useAppSelector(selectSearchStatus);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalFullDetails, setIsOpenModalFullDetails] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [companies, setCompanies] = useState<PagedResult<CompanyModel>>({
    ...INIT_PAGED_RESULT_COMPANY,
  });
  const [isOpenActivitiesSelectionModal, setIsOpenActivitiesSelectionModal] =
    useState<boolean>(false);

  const [textualAds, setTextualAds] = useState<TextualAdvertisementModel[]>([]);

  const [advanceSearchGlobal, setAdvanceSearchGlobal] =
    useState<AdvanceSearchFilterModel>({ ...INIT_ADVANCE_SEARCH });
  const [advanceSearch, setAdvanceSearch] = useState<AdvanceSearchFilterModel>({
    ...INIT_ADVANCE_SEARCH,
  });
  const [showAds, setShowAds] = useState(false);

  const pageSizes = [5, 10, 15, 20];

  const handleSearch = () => {
    
    if (
      advanceSearch.companyName.length > 0 ||
      advanceSearch.categoryIds.length > 0 ||
      advanceSearch.placeIds.length > 0
    ) {
      setShowAds(true);
    } else {
      setShowAds(true);
      dispatch(resetSearch());
    }
    const updatedSearch: AdvanceSearchFilterModel = {
      ...advanceSearch,
      pageNumber: 1,
    };
    if (currentAccount) {
      console.log("ovde ssssss")
      dispatch(getCompaniesAuthorized({ ...updatedSearch }));
      setAdvanceSearch({ ...updatedSearch });
      dispatch(resetSearch());
    } else {
      console.log("ovde sam")
      dispatch(getCompaniesUnauthorized({ ...updatedSearch }));
      setAdvanceSearch({ ...updatedSearch });
      dispatch(resetSearch());
    }
  };

  const handleSelectedItem = (item: any) => {
    setSelectedItem(item);
    setIsOpenModalFullDetails(true);
  };

  const handleSelectedItemTextualAds = (item: any) => {
    setSelectedItem(item);
    setIsOpenModal(true);
  };

  const handleRemoveResult = () => {
    dispatch(resetSearch());
    setAdvanceSearchGlobal(INIT_ADVANCE_SEARCH);
    setCompanies(INIT_PAGED_RESULT_COMPANY);
    setSelectedPlace(null);
  };

  const toggleModal = () => {
    setIsOpenModalFullDetails(!isOpenModalFullDetails);
    setSelectedItem(null);
  };

  const toggleModalTextualAds = () => {
    setIsOpenModal(!isOpenModal);
    setSelectedItem(null);
  };

  const onChangePageSize = (e: SelectChangeEvent<number>) => {
    const newPageSize = +e.target.value;

    const updatedSearchGlobal: AdvanceSearchFilterModel = {
      ...advanceSearchGlobal,
      pageSize: newPageSize,
      pageNumber: 1,
    };

    setAdvanceSearchGlobal({ ...updatedSearchGlobal });
    dispatch(getCompaniesUnauthorized(updatedSearchGlobal));
  };

  const onChangePageNumber = (
    event: ChangeEvent<unknown>,
    page: number
  ): void => {
    if (
      advanceSearch.companyName.length > 0 ||
      advanceSearch.categoryIds.length > 0 ||
      advanceSearch.placeIds.length > 0
    ) {
      setShowAds(true);
    } else {
      setShowAds(true);
      dispatch(resetSearch());
    }
    const updatedSearch: AdvanceSearchFilterModel = {
      ...advanceSearch,
      pageNumber: page,
    };
    if (currentAccount) {
      console.log("ovde ssssss")
      dispatch(getCompaniesAuthorized({ ...updatedSearch }));
      setAdvanceSearch({ ...updatedSearch });
      dispatch(resetSearch());
    } else {
      console.log("ovde sam")
      dispatch(getCompaniesUnauthorized({ ...updatedSearch }));
      setAdvanceSearch({ ...updatedSearch });
      dispatch(resetSearch());
    }
  };

  const handleCloseActivitySelectionModal = () => {
    setIsOpenActivitiesSelectionModal(false);
    dispatch(updateAfterSelectingCategories());
  };

  useEffect(() => {
    const updateSliderHeight = () => {
      if (
        divRef.current &&
        sliderRef.current &&
        companySearchResponse?.pagedResultCompany.pageSize
      ) {
        let gridHeight = 0;
        if (companies.items.length !== 0) {
          gridHeight = divRef.current.offsetHeight;
        }
        sliderRef.current.style.height = `${gridHeight}px`;
      }
    };
    const handleResize = () => {
      updateSliderHeight();
    };
    updateSliderHeight();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [
    companies,
    textualAds,
    companySearchResponse?.pagedResultCompany.pageSize,
  ]);

  useEffect(() => {
    setCompanies(
      companySearchResponse?.pagedResultCompany ?? {
        ...INIT_PAGED_RESULT_COMPANY,
      }
    );
    setTextualAds(companySearchResponse?.textAdvertisements ?? []);
  }, [companySearchResponse]);

  useEffect(() => {
    dispatch(getAllBanners());
  }, []);

  useEffect(() => {
    if (advanceSearchGlobal.companyName.length >= 3) {
      setShowAds(true);
    } else {
      setShowAds(false);
    }
  }, [advanceSearchGlobal]);

  const resetPassResponse = useAppSelector(selectResetPasswordResponse);
  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;
    if (
      resetPassResponse?.isActive === true &&
      loginResponse?.areCategoriesSetUp === false
    ) {
      timeoutId = setTimeout(() => {
        setIsOpenActivitiesSelectionModal(true);
      }, 3000);
    } else {
      setIsOpenActivitiesSelectionModal(false);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      resetResetPassword();
    };
  }, [resetPassResponse, loginResponse]);

  const [removedFavoriteId, setRemovedFavoirteId] = useState<string | null>(
    null
  );
  const [addFavoriteId, setAddFavoriteId] = useState<string | null>(null);
  const [isFavoriteRemoved, setIsFavoriteRemoved] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<CompanyModel | null>(
    null
  );

  useEffect(() => {
    if (removedFavoriteId && selectedCompany) {
      setCompanies((prevCompanies: PagedResult<CompanyModel>) => {
        const updatedItems = prevCompanies.items.map((companyItem) => {
          if (companyItem.generalData.id === removedFavoriteId) {
            return {
              ...companyItem,
              generalData: {
                ...companyItem.generalData,
                isFavorite: !selectedCompany.generalData.isFavorite,
              },
            };
          }
          return companyItem;
        });
        return {
          ...prevCompanies,
          items: updatedItems,
        };
      });
      setRemovedFavoirteId(null);
    }

    if (addFavoriteId && selectedCompany) {
      setCompanies((prevCompanies: PagedResult<CompanyModel>) => {
        const updatedItems = prevCompanies.items.map((companyItem) => {
          if (companyItem.generalData.id === addFavoriteId) {
            return {
              ...companyItem,
              generalData: {
                ...companyItem.generalData,
                isFavorite: true,
              },
            };
          }
          return companyItem;
        });
        return {
          ...prevCompanies,
          items: updatedItems,
        };
      });
      setAddFavoriteId(null);
    }
  }, [removedFavoriteId, addFavoriteId, selectedCompany]);

  useEffect(() => {}, [companies]);

  useEffect(() => {
    if (overallRatingStatus === ApiStatus.Fulfilled) {
      handleSearch();
      toast.success(
        `${t("overallRatingModal.overallRatingSuccessMessage")} ${
          selectedCompany?.generalData.name
        }`,
        {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: true,
          onClose: () => {
            dispatch(resetOverallRatingStatus());
            dispatch(resetCompanyById());
          },
        }
      );
    }
  }, [overallRatingStatus]);

  return (
    <div style={{ overflow: "hidden" }}>
      <SubHeader />
      {isMobile && (
        <MobileHeader
          handleSearch={handleSearch}
          advanceSearchGlobal={advanceSearchGlobal}
          setAdvanceSearchGlobal={setAdvanceSearchGlobal}
          advanceSearch={advanceSearch}
          setAdvanceSearch={setAdvanceSearch}
          setShowAds={setShowAds}
        />
      )}
      {isDesktop && (
        <MainHeader
          handleSearch={handleSearch}
          advanceSearchGlobal={advanceSearchGlobal}
          setAdvanceSearchGlobal={setAdvanceSearchGlobal}
          advanceSearch={advanceSearch}
          setAdvanceSearch={setAdvanceSearch}
          setShowAds={setShowAds}
          handleRemoveResult={handleRemoveResult}
          selectedPlace={selectedPlace}
          setSelectedPlace={setSelectedPlace}
        />
      )}
      <Grid container spacing={2} sx={{ padding: "20px" }}>
        {currentAccount && isDesktop && (
          <Grid item md={2}>
            <Sidebar />
          </Grid>
        )}

        {companySearchResponse?.pagedResultCompany.items !== undefined && (
          <>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              mt={2}
              ml={currentAccount ? 7 : isMobile ? 0 : 2}
            >
              <Grid
                item
                lg={8.5}
                md={12}
                sm={12}
                xs={12}
                display="flex"
                justifyContent={"space-between"}
                mb={-3}
              >
                <Grid item>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "10px", color: "gray" }}
                  >
                    {t("homePage.resultsPerPage")}
                  </Typography>
                  <TextField
                    select
                    variant="standard"
                    id="demo-simple-select"
                    value={advanceSearchGlobal.pageSize}
                    onChange={(e: any) => onChangePageSize(e)}
                    placeholder="Rez. po stranici"
                    InputProps={{
                      disableUnderline: true,
                    }}
                  >
                    {pageSizes.map((x, i) => (
                      <MenuItem key={i} value={x}>
                        {x}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item sx={{ textAlign: "right" }}>
                  <Pagination
                    sx={{ fontSize: "5px" }}
                    size="small"
                    count={companies.totalPages}
                    page={companies.pageNumber}
                    onChange={onChangePageNumber}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              md={8.5}
              xs={12}
              ref={divRef}
              mb={4}
              ml={currentAccount && !isMobile ? 6.5 : 0}
            >
              {companies.items.length > 0 && (
                <>
                  {isDesktop &&
                    showAds &&
                    companies.items?.map((company: any) => (
                      <SearchResultLayout
                        setSelectedCompany={setSelectedCompany}
                        key={company.generalData.id}
                        company={company}
                        onClick={() => handleSelectedItem(company)}
                        setRemovedFavoirteId={setRemovedFavoirteId}
                        setAddFavoriteId={setAddFavoriteId}
                        overallRatingStatus={overallRatingStatus}
                      />
                    ))}

                  {isMobile &&
                    showAds &&
                    companies.items?.map((company) => (
                      <SearchResultLayoutMobile
                        key={company.generalData.id}
                        company={company}
                        onClick={() => handleSelectedItem(company)}
                        overallRatingStatus={overallRatingStatus}
                        setAddFavoriteId={setAddFavoriteId}
                        setSelectedCompany={setSelectedCompany}
                      />
                    ))}
                </>
              )}
            </Grid>
            {isMobile && (
              <Grid
                item
                md={12}
                xs={12}
                sx={{ display: companies.items.length !== 0 ? "" : "none" }}
                mt={-2}
              >
                <Grid
                  item
                  lg={8.5}
                  md={12}
                  sm={12}
                  xs={12}
                  display="flex"
                  justifyContent={"space-between"}
                >
                  <Grid item ml={2}>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "10px", color: "gray" }}
                    >
                      {t("homePage.resultsPerPage")}
                    </Typography>
                    <TextField
                      select
                      variant="standard"
                      id="demo-simple-select"
                      value={advanceSearch.pageSize}
                      onChange={(e: any) => onChangePageSize(e)}
                      placeholder="Rez. po stranici"
                      InputProps={{
                        disableUnderline: true,
                      }}
                    >
                      {pageSizes.map((x, i) => (
                        <MenuItem key={i} value={x}>
                          {x}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item sx={{ textAlign: "right" }}>
                    <Pagination
                      sx={{ fontSize: "5px" }}
                      size="small"
                      count={companies.totalPages}
                      page={companies.pageNumber}
                      onChange={onChangePageNumber}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid
              item
              md={currentAccount ? 3 : 3.5}
              xs={12}
              ref={sliderRef}
              mb={4}
            >
              {showAds && (
                <Box
                  sx={{
                    padding: "15px",
                    borderRadius: "15px",
                    height: "100%",
                    overflow: "auto",
                    display: companies.items.length !== 0 ? "" : "none",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                  }}
                  my={2}
                >
                  {textualAds.length !== 0 ? (
                    textualAds.map((ad, i) => (
                      <TextualAdvertisingSection
                        key={i}
                        advertisement={ad}
                        count={i}
                        onClick={(e: any) => handleSelectedItemTextualAds(ad)}
                      />
                    ))
                  ) : (
                    <PromoteBusinessSection />
                  )}
                </Box>
              )}
            </Grid>

            {isDesktop && (
              <Grid
                item
                md={12}
                xs={12}
                sx={{ display: companies.items.length !== 0 ? "" : "none" }}
                mt={2}
                ml={currentAccount ? 7 : 2}
              >
                <Grid
                  item
                  lg={8.5}
                  md={6}
                  sm={6}
                  xs={12}
                  display="flex"
                  justifyContent={"space-between"}
                  mt={-5}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "10px", color: "gray" }}
                    >
                      {t("homePage.resultsPerPage")}
                    </Typography>
                    <TextField
                      select
                      variant="standard"
                      id="demo-simple-select"
                      value={advanceSearchGlobal.pageSize}
                      onChange={(e: any) => onChangePageSize(e)}
                      placeholder={t("homePage.resultsPerPage") ?? ""}
                      InputProps={{
                        disableUnderline: true,
                      }}
                    >
                      {pageSizes.map((x, i) => (
                        <MenuItem key={i} value={x}>
                          {x}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item sx={{ textAlign: "right" }}>
                    <Pagination
                      sx={{ fontSize: "5px" }}
                      size="small"
                      count={companies.totalPages}
                      page={companies.pageNumber}
                      onChange={onChangePageNumber}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        )}
        <Grid item md={12} xs={12}>
          <BannerSection banners={banners} />
        </Grid>
      </Grid>
      <FullDetailsModal
        handleClose={toggleModal}
        open={isOpenModalFullDetails}
        company={selectedItem}
      />
      <FullDetailsModalTextualAds
        handleClose={toggleModalTextualAds}
        open={isOpenModal}
        company={selectedItem}
      />
      <ActivitySelectionModal
        handleClose={handleCloseActivitySelectionModal}
        open={isOpenActivitiesSelectionModal}
      />
    </div>
  );
}
